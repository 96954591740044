import { dev } from "$app/environment"
import { SENTRY_DSN, SENTRY_ENV, SERVER_LOCATION } from "$env/static/public"
import {
  browserTracingIntegration,
  handleErrorWithSentry,
  init,
  replayIntegration
} from "@sentry/sveltekit"
import { type HandleClientError } from "@sveltejs/kit"
import { toast } from "svelte-sonner"

init({
  // dsn: "https://b85162f605c14b3d86c8771a4fe3cf0c@o4504316999106560.ingest.us.sentry.io/4505606377701376",
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: dev ? 0 : 0.05,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: dev ? 0 : 0.05,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: dev ? 0 : 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [browserTracingIntegration(), replayIntegration()],
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [SERVER_LOCATION]
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
})

const myErrorHandler: HandleClientError = ({ error, event }) => {
  // @ts-expect-error
  toast.error(`An error occurred: ${error.message || error}`)
  console.error("An error occurred on the client side:", error, event)
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
// export const handleError = handleErrorWithSentry()
export const handleError = handleErrorWithSentry(myErrorHandler)
