import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [];

export const dictionary = {
		"/(authenticated)/(architect)/(app)": [10,[2,3,4]],
		"/404": [39],
		"/(authenticated)/(architect)/(app)/apps": [11,[2,3,4]],
		"/(authenticated)/(architect)/(app)/apps/new": [16,[2,3,4]],
		"/(authenticated)/(architect)/(app)/apps/[id]": [12,[2,3,4,5]],
		"/(authenticated)/(architect)/(app)/apps/[id]/block": [13,[2,3,4,5]],
		"/(authenticated)/(architect)/(app)/apps/[id]/wizard": [14,[2,3,4,5,6]],
		"/(authenticated)/(architect)/(app)/apps/[id]/wizard/step/[stepId]": [15,[2,3,4,5,6]],
		"/(authenticated)/(architect)/(app)/attributes": [17,[2,3,4,7]],
		"/(authenticated)/(architect)/(app)/attributes/[id]": [18,[2,3,4,7]],
		"/confirm": [40],
		"/(authenticated)/(architect)/editor/[blockId]": [32,[2,3]],
		"/(lobby)/forgot-password": [34,[9]],
		"/(lobby)/login": [35,[9]],
		"/(authenticated)/(architect)/(app)/playground/sdk": [19,[2,3,4]],
		"/(authenticated)/(architect)/(app)/playground/store/[id]": [20,[2,3,4]],
		"/(authenticated)/(architect)/(app)/playground/wizard-converter": [21,[2,3,4]],
		"/(authenticated)/(architect)/(app)/playground/wizard-generator": [22,[2,3,4]],
		"/(authenticated)/(architect)/(app)/publications": [23,[2,3,4]],
		"/(authenticated)/publications/[id]": [33,[2]],
		"/(lobby)/reset-password": [36,[9]],
		"/(authenticated)/(architect)/(app)/settings": [24,[2,3,4]],
		"/(lobby)/signup": [37,[9]],
		"/(authenticated)/(architect)/(app)/studio": [25,[2,3,4]],
		"/(lobby)/team-select": [38,[9]],
		"/(authenticated)/(architect)/(app)/team": [26,[2,3,4,8]],
		"/(authenticated)/(architect)/(app)/team/(checkout)/checkout_success": [27,[2,3,4,8]],
		"/(authenticated)/(architect)/(app)/team/groups": [28,[2,3,4,8]],
		"/(authenticated)/(architect)/(app)/team/groups/[groupId]": [29,[2,3,4,8]],
		"/(authenticated)/(architect)/(app)/team/plans": [30,[2,3,4,8]],
		"/(authenticated)/(architect)/(app)/team/users": [31,[2,3,4,8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';